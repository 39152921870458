import {COUNTRIES_CURRENCY_FETCHED, COUNTRIES_CURRENCY_FETCHING, COUNTRIES_CURRENCY_FINISHED} from "../constants/CountriesCurrency";

const initState = {
    loading: false,
    list: [],
    userCountryCurrency: null
}

const countriesCurrency = (state = initState, action) => {
    switch (action.type) {
        case COUNTRIES_CURRENCY_FETCHING:
            return {
                ...state,
                loading: true,
            }
        case COUNTRIES_CURRENCY_FETCHED:
            return {
                ...state,
                loading: false,
                list: action.payload.countriesCurrency,
                userCountryCurrency: action.payload.userCountryCurrency
            }
        case COUNTRIES_CURRENCY_FINISHED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}

export default countriesCurrency