import React from 'react';

function LogoTypo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 187.56 84.49" {...props}>
            <defs>
                <linearGradient id="linear-gradient-logo-typo" x1="9.29" y1="46.89" x2="84.47" y2="46.89"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#3f3300"/>
                    <stop offset="1" stopColor="#ff8700" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="linear-gradient-logo-typo-2" x1="79.39" y1="33.15" x2="22.57" y2="38.8"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f39200"/>
                    <stop offset="1" stopColor="#f39200" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="linear-gradient-logo-typo-3" x1="50.89" y1="5.03" x2="8.64" y2="65.33"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f39200" stopOpacity="0"/>
                    <stop offset="0.51" stopColor="#fff"/>
                    <stop offset="1" stopColor="#f39200" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="linear-gradient-logo-typo-4" x1="21.48" y1="21.38" x2="46.91" y2="46.82"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff"/>
                    <stop offset="0.99" stopColor="#fff" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="linear-gradient-logo-typo-5" x1="69.52" y1="82.72" x2="59.11" y2="52.38"
                                xlinkHref="#linear-gradient-logo-typo-2"/>
                <linearGradient id="linear-gradient-logo-typo-6" x1="45.89" y1="21.79" x2="70.73" y2="82.62"
                                xlinkHref="#linear-gradient-logo-typo-3"/>
                <linearGradient id="linear-gradient-logo-typo-7" x1="59.48" y1="67.9" x2="58.59" y2="81.83"
                                xlinkHref="#linear-gradient-logo-typo"/>
                <linearGradient id="linear-gradient-logo-typo-8" x1="53.96" y1="70.71" x2="64.95" y2="70.71"
                                xlinkHref="#linear-gradient-logo-typo-4"/>
                <linearGradient id="linear-gradient-logo-typo-9" x1="24.32" y1="2328.81" x2="58.79" y2="2304.22"
                                gradientTransform="matrix(1, 0, 0, -1, 0, 2366.95)"
                                xlinkHref="#linear-gradient-logo-typo-2"/>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        d="M64,53.51a24.49,24.49,0,1,1,2.75-11.26c0,.15,0,.29,0,.44H84.47c0-.15,0-.29,0-.44A42.25,42.25,0,0,0,48.82.51,43.14,43.14,0,0,0,42.25,0a42.25,42.25,0,1,0,0,84.49A43.14,43.14,0,0,0,48.82,84,42.2,42.2,0,0,0,75.24,68.62Z"
                        fill="#fed700"/>
                    <path
                        d="M64,53.51a24.53,24.53,0,1,1,2.73-10.82H84.47a42.14,42.14,0,0,0-8.07-25.3,42.24,42.24,0,0,0-59,59A42.22,42.22,0,0,0,72.1,72.11a42.81,42.81,0,0,0,3.13-3.5Z"
                        fill="url(#linear-gradient-logo-typo)"/>
                    <path
                        d="M21.66,21.67a42.24,42.24,0,0,1,54.73-4.29A42.21,42.21,0,0,0,48.82.51,43.14,43.14,0,0,0,42.25,0,42.24,42.24,0,0,0,17.36,76.37,42.24,42.24,0,0,1,21.66,21.67Z"
                        fill="url(#linear-gradient-logo-typo-2)"/>
                    <g opacity="0.68">
                        <path
                            d="M21.66,21.67a42.24,42.24,0,0,1,54.73-4.29A42.21,42.21,0,0,0,48.82.51,43.14,43.14,0,0,0,42.25,0,42.24,42.24,0,0,0,17.36,76.37,42.24,42.24,0,0,1,21.66,21.67Z"
                            fill="url(#linear-gradient-logo-typo-3)"/>
                    </g>
                    <path d="M76.4,17.28a42.25,42.25,0,0,0-59,59" fill="none" strokeMiterlimit="10" strokeWidth="0.5"
                          stroke="url(#linear-gradient-logo-typo-4)"/>
                    <path
                        d="M83.66,84.49h-12a3.51,3.51,0,0,1-3-1.73l-27.9-37.5a1.71,1.71,0,0,1,1.47-2.57h12a3.51,3.51,0,0,1,3,1.73l27.9,37.5A1.7,1.7,0,0,1,83.66,84.49Z"
                        fill="#fed700"/>
                    <path
                        d="M83.66,84.49h-12a3.51,3.51,0,0,1-3-1.73l-27.9-37.5a1.71,1.71,0,0,1,1.47-2.57h12a3.51,3.51,0,0,1,3,1.73l27.9,37.5A1.7,1.7,0,0,1,83.66,84.49Z"
                        fill="url(#linear-gradient-logo-typo-5)"/>
                    <path
                        d="M83.66,84.49h-12a3.51,3.51,0,0,1-3-1.73l-27.9-37.5a1.71,1.71,0,0,1,1.47-2.57h12a3.51,3.51,0,0,1,3,1.73l27.9,37.5A1.7,1.7,0,0,1,83.66,84.49Z"
                        opacity="0.79" fill="url(#linear-gradient-logo-typo-6)"/>
                    <path d="M54,63.79s4.36,9.93,2.71,18.27a34.54,34.54,0,0,0,7.87-4Z"
                          fill="url(#linear-gradient-logo-typo-7)"/>
                    <line x1="54.17" y1="63.59" x2="64.75" y2="77.82" fill="none" strokeMiterlimit="10"
                          strokeWidth="0.5" stroke="url(#linear-gradient-logo-typo-8)"/>
                    <path
                        d="M94.88,22.61V28c0,1.13.55,1.27,1.88,1.19v1.69a6.41,6.41,0,0,1-1.41.15c-1.64,0-2.54-.74-2.54-2.85v-5.6H91.36V20.93h1.45V17.78h2.07v3.15h1.88v1.68Z"/>
                    <path
                        d="M106.37,24v6.9h-2.08V24.61c0-1.35-.57-2.21-2-2.21a2.39,2.39,0,0,0-2.59,2.44v6.08h-2v-14h2v5.28h0A3.77,3.77,0,0,1,103,20.65,3.11,3.11,0,0,1,106.37,24Z"/>
                    <path
                        d="M114.64,27.87h2.06a4.39,4.39,0,0,1-4.56,3.35c-3.11,0-5-2.15-5-5.28s1.9-5.29,4.91-5.29c3.23,0,4.81,2.25,4.81,5.81h-7.66a2.86,2.86,0,0,0,2.89,3.08A2.47,2.47,0,0,0,114.64,27.87Zm-5.45-2.93h5.61A2.58,2.58,0,0,0,112,22.28,2.66,2.66,0,0,0,109.19,24.94Z"/>
                    <path
                        d="M112.65,36.33v27h-6.26V54.37h-.08A7.51,7.51,0,0,1,100.52,57c-5.32,0-8.73-4.07-8.73-10.65S95.2,35.7,100.52,35.7a7.47,7.47,0,0,1,5.79,2.62h.08v-2Zm-6.38,10c0-3.91-1.56-5.94-4.11-5.94s-4.11,2-4.11,5.94,1.57,5.95,4.11,5.95S106.27,50.26,106.27,46.34Z"/>
                    <path
                        d="M123.8,40c-2.27,0-3.36.87-3.56,2.78h-5.87c.2-3.83,2.94-7.08,9.47-7.08,5.64,0,8.77,2.19,8.77,8.49v9.2c0,1.06.39,1.49,1.18,1.49v1.48a9.94,9.94,0,0,1-3.64.63c-2.82,0-3.61-1.33-3.61-2.74h-.07a8.56,8.56,0,0,1-6.3,2.74c-4.23,0-6.58-2.39-6.58-5.75,0-4,2.94-5.83,7.63-6.77,2.58-.51,5.13-.9,5.13-2.59C126.35,40.71,125.53,40,123.8,40Zm2.55,9V46.62a20.09,20.09,0,0,1-3.21.9c-2.47.55-3.29,1.56-3.29,2.82,0,1.64,1,2.35,2.51,2.35C125.06,52.69,126.35,51,126.35,49.05Z"/>
                    <path
                        d="M166.07,42.82V56.36h-6.34V44.82c0-3-.62-4-2.85-4-2,0-3.25,1.21-3.25,4V56.36h-6.26V44.82c0-3-.63-4-2.86-4-2,0-3.25,1.21-3.25,4V56.36H135v-20h6.26v2.89h.08a7.16,7.16,0,0,1,6.26-3.52,5.35,5.35,0,0,1,5.24,3.52h.08c1.73-2,3.49-3.52,6.74-3.52C163.69,35.7,166.07,38.24,166.07,42.82Z"/>
                    <path
                        d="M181,50.3h6.23c-1.1,4-4.23,6.69-9.67,6.69-6.65,0-10.68-4.38-10.68-10.65,0-6.1,3.95-10.64,10.33-10.64,6.85,0,10.33,5,10.33,12.29h-14.4a4.12,4.12,0,0,0,4.3,4.3C179.61,52.29,180.63,51.28,181,50.3Zm-7.86-6.11h8.14c0-2.78-1.69-4.19-4.07-4.19S173.16,41.41,173.16,44.19Z"/>
                    <path d="M146.2,70.79a1.39,1.39,0,1,1,2.77,0,1.39,1.39,0,1,1-2.77,0Z"/>
                    <path
                        d="M154.66,62.57c-2.22,0-3.17,1.89-3.17,4s.93,4,3.17,4a2.55,2.55,0,0,0,2.73-2.56h2.26a4.7,4.7,0,0,1-5,4.41c-3.3,0-5.43-2.37-5.43-5.81s2.13-5.81,5.4-5.81a4.75,4.75,0,0,1,5,4.32h-2.26A2.55,2.55,0,0,0,154.66,62.57Z"/>
                    <path
                        d="M165.44,72.34c-3.44,0-5.53-2.39-5.53-5.81s2.09-5.81,5.53-5.81S171,63.11,171,66.53,168.88,72.34,165.44,72.34Zm0-1.85c2.15,0,3.27-1.7,3.27-4s-1.12-4-3.27-4-3.27,1.68-3.27,4S163.29,70.49,165.44,70.49Z"/>
                    <path
                        d="M187.4,64.42V72h-2.28v-7c0-1.49-.3-2.44-1.86-2.44a2.42,2.42,0,0,0-2.47,2.69V72h-2.28v-7c0-1.49-.3-2.44-1.88-2.44a2.43,2.43,0,0,0-2.47,2.69V72H171.9V61h2.26v1.42h0a3.81,3.81,0,0,1,3.27-1.72,2.84,2.84,0,0,1,2.93,1.89h0a4.36,4.36,0,0,1,3.6-1.89C186.26,60.72,187.4,62.05,187.4,64.42Z"/>
                    <path
                        d="M71.5,70.11a42.31,42.31,0,0,1-43-2,27.8,27.8,0,0,1-5.25-4l0,0h0a27,27,0,0,1,12-45.31A24.46,24.46,0,0,0,18.49,36a26.94,26.94,0,0,0-.6,3.05v0q-.09.75-.15,1.5v.09a24.19,24.19,0,0,0,0,3.07.28.28,0,0,0,0,.09c0,.47.07.94.13,1.41a.78.78,0,0,0,0,.16,24.89,24.89,0,0,0,.56,3l.06.21c.11.41.23.81.36,1.21L19,50a23.45,23.45,0,0,0,1.1,2.72,3.59,3.59,0,0,0,.17.34c.16.33.33.66.51,1l.21.38c.22.38.45.76.69,1.13l.11.16c.25.38.52.75.79,1.12l.33.43.6.74.39.45c.24.27.49.54.75.8l.24.26a24.51,24.51,0,0,0,33.73.87c.31-.28.61-.57.91-.87C64.39,54.63,73.16,69.22,71.5,70.11Z"
                        fill="url(#linear-gradient-logo-typo-9)"/>
                </g>
            </g>
        </svg>
    );
}

export default LogoTypo;
