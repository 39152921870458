import React from 'react';
import {Badge} from "antd";
import {useSelector} from "react-redux";
import {ORDER_NOTIFICATION_TYPES} from "../../constants/OrderConstant";
import {COMPETITION_NOTIFICATION_TYPES} from "../../constants/NotificationConstant";

function NavigationMenuNotify({item}) {

    const unreadNotifications = useSelector(state => state.notifications)

    function canShowBadge(item) {
        if (item.key === 'orders-management') {
            return hasOrderChangeStatusNotification(unreadNotifications?.unreads)
        }

        if (item.key === 'competition-management') {
            return hasCompetitionNotification(unreadNotifications?.unreads)
        }
    }

    function hasOrderChangeStatusNotification(notifications) {
        if (notifications.length) {
            return notifications.filter(notification => ORDER_NOTIFICATION_TYPES.includes(notification.type)).length > 0
        }
        return false;
    }

    function hasCompetitionNotification(notifications) {
        if (notifications.length) {
            return notifications.filter(notification => COMPETITION_NOTIFICATION_TYPES.includes(notification.type)).length > 0
        }
        return false;
    }

    return (
        <>
            {item.notifiable && canShowBadge(item) && (
                <span style={{float: 'right'}}>
                    <Badge status="error"/>
                </span>
            )}
        </>

    );
}

export default NavigationMenuNotify;