import {BACKEND_SERVER_URL} from "../constants/ApiConstant";

// export const generateImageLink = (link) => {
//     if (link.includes('http')) {
//         return link
//     } else {
//         return `${BACKEND_SERVER_URL}/${link}`
//     }
// }

export const generateImageLink = (link, thumbnail = false) => {

    if (!link || typeof link == 'undefined' || link === '' || link.length < 2) {
        // console.log("come");
        return ''
    }

    if (link.includes('http')) {
        return link
    } else {
        if (thumbnail) {
            const l = link.split('/').length
            const fileName = link.split('/')[l-1]
            const newLink = link.replace(fileName, 'thumbnails/'+fileName)

            return `${BACKEND_SERVER_URL}/${newLink}`
        } else {
            return `${BACKEND_SERVER_URL}/${link}`
        }
    }
}