import React from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';

function RouteInterceptor({children, isAuthenticated, ...rest}) {

    const user = useSelector(state => state.auth.user)

    return (
        <Route
            {...rest}
            render={({location}) => {

                let redirectToProfile = false;
                if (user) {
                    //redirect sponsor if not completed profile info
                    const fieldsNotCompleted =
                        !user?.name ||
                        !user?.family ||
                        !user?.email ||
                        !user?.country_id ||
                        !user?.profile?.company_name ||
                        !user?.profile?.phone_number ||
                        !user?.profile?.locations.length > 0;

                    // console.log(fieldsNotCompleted);
                    const redirectToSponsorProfileConditions = user.type === 'sponsor' && fieldsNotCompleted
                    // console.log(redirectToSponsorProfileConditions);
                    // console.log("================");
                    redirectToProfile = location.pathname !== APP_PREFIX_PATH + "/profile/edit" && redirectToSponsorProfileConditions
                }

                return isAuthenticated ? (
                    redirectToProfile ? (
                        <Redirect to={APP_PREFIX_PATH + '/profile/edit'}/>
                    ) : (
                        children
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: AUTH_PREFIX_PATH,
                            state: {from: location}
                        }}
                    />
                )
            }
            }
        />
    );
}

export const Views = (props) => {
    const {locale, token, location, direction} = props;
    const currentAppLocale = AppLocale[locale];
    useBodyClass(`dir-${direction}`);
    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={APP_PREFIX_PATH}/>
                    </Route>
                    <Route path={AUTH_PREFIX_PATH}>
                        <AuthLayout direction={direction}/>
                    </Route>
                    <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                        <AppLayout direction={direction} location={location}/>
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}


const mapStateToProps = ({theme, auth}) => {
    const {locale, direction} = theme;
    const {token} = auth;
    return {locale, direction, token}
};

export default withRouter(connect(mapStateToProps)(Views));