
const localApiBaseUrl = 'http://api.theqame.test/api/panel'
const localBackendServerUrl = 'http://api.theqame.test'
const localFrontEndServerUrl = 'http://localhost:3000'

const devApiBaseUrl = 'https://dev-gateway.theqame.com/api/panel'
const devBackendServerUrl = 'https://dev-gateway.theqame.com'
const devFrontEndServerUrl = 'https://dev-panel.theqame.com'

const productionApiBaseUrl = 'https://gateway.theqame.com/api/panel'
const productionBackendServerUrl = 'https://gateway.theqame.com'
const productionFrontEndServerUrl = 'https://panel.theqame.com'

export const API_BASE_URL = process.env.REACT_APP_APP_ENV === 'PROD' ? `${productionApiBaseUrl}` : (process.env.REACT_APP_APP_ENV === 'DEV' ? `${devApiBaseUrl}` : `${localApiBaseUrl}`)
export const BACKEND_SERVER_URL = process.env.REACT_APP_APP_ENV === 'PROD' ? `${productionBackendServerUrl}` : (process.env.REACT_APP_APP_ENV === 'DEV' ? `${devBackendServerUrl}` : `${localBackendServerUrl}`)
export const FRONTEND_SERVER_URL = process.env.REACT_APP_APP_ENV === 'PROD' ? `${productionFrontEndServerUrl}` : (process.env.REACT_APP_APP_ENV === 'DEV' ? `${devFrontEndServerUrl}` : `${localFrontEndServerUrl}`)


console.log(API_BASE_URL);