import {
    ApartmentOutlined, HeartOutlined, DashboardOutlined,
    DollarOutlined, FolderOpenOutlined, FileDoneOutlined,
    GlobalOutlined, NotificationOutlined, UserOutlined, WalletOutlined,
    PlaySquareOutlined, HddOutlined, TableOutlined, TeamOutlined, SolutionOutlined,
    DribbbleOutlined, BulbOutlined, AuditOutlined,
    TrophyOutlined, SettingOutlined, SmileOutlined, StarOutlined, FunctionOutlined, CarryOutOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {AUTH_TYPE} from "../redux/constants/Auth";

const dashBoardAdminNavTree = [{
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'Dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'dashboards-default',
            path: `${APP_PREFIX_PATH}/dashboards/admin`,
            title: 'Home',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
}, {
    key: 'Users Management',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Users Management',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'users-management',
            path: `${APP_PREFIX_PATH}/users`,
            title: 'Users',
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'participants-management',
            path: `${APP_PREFIX_PATH}/participants`,
            title: 'Players',
            icon: SmileOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'brands-management',
            path: `${APP_PREFIX_PATH}/brands`,
            title: 'Brands',
            icon: StarOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
}, {
    key: 'Orders Management',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Orders Management',
    icon: AuditOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'orders-management',
            path: `${APP_PREFIX_PATH}/orders`,
            title: 'Orders',
            icon: AuditOutlined,
            breadcrumb: false,
            submenu: [],
            notifiable: true,
        },
    ]
}, {
    key: 'Quests Management',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Quests Management',
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'quests-management',
            path: `${APP_PREFIX_PATH}/quests`,
            title: 'Quests',
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'surveys-management',
            path: `${APP_PREFIX_PATH}/surveys`,
            title: 'Surveys',
            icon: HddOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'mini-game-management',
            path: `${APP_PREFIX_PATH}/miniGames`,
            title: 'Mini Games',
            icon: DribbbleOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
}, {
    key: 'Competitions',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Competitions',
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [
        // {
        //     key: 'competition-management',
        //     path: `${APP_PREFIX_PATH}/competitions`,
        //     title: 'Competitions',
        //     icon: TrophyOutlined,
        //     breadcrumb: false,
        //     submenu: [],
        //     notifiable: true,
        // },
        {
            key: 'public-competition-management',
            path: `${APP_PREFIX_PATH}/competitions`,
            title: 'Public Competitions',
            icon: TrophyOutlined,
            breadcrumb: false,
            submenu: [],
            notifiable: true,
        },
        {
            key: 'event-competition-management',
            path: `${APP_PREFIX_PATH}/event-competitions`,
            title: 'Event Competitions',
            icon: CarryOutOutlined,
            breadcrumb: false,
            submenu: [],
            notifiable: true,
        },
        {
            key: 'loyalty-club-competition-management',
            path: `${APP_PREFIX_PATH}/club-competitions`,
            title: 'Loyalty Club Competitions',
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
            notifiable: true,
        },
    ]
}, {
    key: 'Finance',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Finance',
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'wallet-withdraw-requests-management',
            path: `${APP_PREFIX_PATH}/wallet-withdraw-requests`,
            title: 'Wallet Withdraw Requests',
            icon: WalletOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
},
    {
        key: 'QuestPrices',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Quest Prices',
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'quest-type-prices-management',
                path: `${APP_PREFIX_PATH}/questPrices/questType`,
                title: 'Quest Type Prices',
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'quest-country-prices-management',
                path: `${APP_PREFIX_PATH}/questPrices/country`,
                title: 'Quest Country Prices',
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'quest-gender-prices-management',
                path: `${APP_PREFIX_PATH}/questPrices/gender`,
                title: 'Quest Gender Prices',
                breadcrumb: false,
                submenu: []
            },
        ]
    },
    {
        key: 'Content',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Content',
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'tutorial-management',
                path: `${APP_PREFIX_PATH}/tutorials`,
                title: 'Tutorial',
                icon: FunctionOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'sliders-management',
                path: `${APP_PREFIX_PATH}/sliders`,
                title: 'Slider',
                icon: PlaySquareOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'popup-management',
                path: `${APP_PREFIX_PATH}/popup`,
                title: 'Popup',
                icon: NotificationOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'faq-management',
                path: `${APP_PREFIX_PATH}/faqs`,
                title: 'FAQ',
                icon: BulbOutlined,
                breadcrumb: false,
                submenu: []
            },
        ]
    },
    {
        key: 'Base Information',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Base Information',
        icon: TableOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'interests-management',
                path: `${APP_PREFIX_PATH}/interests`,
                title: 'Interests',
                icon: HeartOutlined,
                breadcrumb: false,
                submenu: []
            },
            // {
            //     key: 'shortlink-management',
            //     path: `${APP_PREFIX_PATH}/shortlinks`,
            //     title: 'ShortLinks',
            //     icon: LinkOutlined,
            //     breadcrumb: false,
            //     submenu: []
            // },
            {
                key: 'currency-management',
                path: `${APP_PREFIX_PATH}/currencies`,
                title: 'Currencies',
                icon: DollarOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'country-management',
                path: `${APP_PREFIX_PATH}/countries`,
                title: 'Countries',
                icon: GlobalOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'industries-management',
                path: `${APP_PREFIX_PATH}/industries`,
                title: 'Industries',
                icon: ApartmentOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'settings-management',
                path: `${APP_PREFIX_PATH}/settings`,
                title: 'Settings',
                icon: SettingOutlined,
                breadcrumb: false,
                submenu: []
            }
        ]
    }]

const profileTree = [{
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    title: 'Profile',
    icon: SmileOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'profile-edit',
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'Edit Profile',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
        }
    ]
}]

export const useNavigationConfig = () => {

    let aclTree;
    switch (localStorage.getItem(AUTH_TYPE)) {
        case 'sponsor':
            aclTree = [
                {
                    key: 'dashboards',
                    path: `${APP_PREFIX_PATH}/dashboards`,
                    title: 'Dashboard',
                    icon: DashboardOutlined,
                    breadcrumb: false,
                    submenu: [
                        {
                            key: 'dashboards-default',
                            path: `${APP_PREFIX_PATH}/`,
                            title: 'Home',
                            icon: DashboardOutlined,
                            breadcrumb: false,
                            submenu: []
                        },
                    ]
                },
                {
                    key: 'Orders Management',
                    path: `${APP_PREFIX_PATH}/`,
                    title: 'Orders Management',
                    icon: AuditOutlined,
                    breadcrumb: false,
                    submenu: [
                        {
                            key: 'orders-management',
                            path: `${APP_PREFIX_PATH}/orders`,
                            title: 'Orders',
                            icon: AuditOutlined,
                            breadcrumb: false,
                            submenu: [],
                            notifiable: true,
                        },
                    ]
                },
                {
                    key: 'Quests Management',
                    path: `${APP_PREFIX_PATH}/`,
                    title: 'Quests',
                    icon: FileDoneOutlined,
                    breadcrumb: false,
                    submenu: [
                        // {
                        //     key: 'quests-management',
                        //     path: `${APP_PREFIX_PATH}/quests`,
                        //     title: 'Quests',
                        //     icon: FileDoneOutlined,
                        //     breadcrumb: false,
                        //     submenu: []
                        // },
                        // {
                        //     key: 'surveys-management',
                        //     path: `${APP_PREFIX_PATH}/surveys`,
                        //     title: 'Surveys',
                        //     icon: HddOutlined,
                        //     breadcrumb: false,
                        //     submenu: []
                        // },
                        {
                            key: 'mini-game-management',
                            path: `${APP_PREFIX_PATH}/miniGames`,
                            title: 'Mini Games',
                            icon: DribbbleOutlined,
                            breadcrumb: false,
                            submenu: []
                        },
                    ]
                }, {
                    key: 'Competitions',
                    path: `${APP_PREFIX_PATH}/`,
                    title: 'Competitions',
                    icon: SolutionOutlined,
                    breadcrumb: false,
                    submenu: [
                        {
                            key: 'public-competition-management',
                            path: `${APP_PREFIX_PATH}/competitions`,
                            title: 'Public Competitions',
                            icon: TrophyOutlined,
                            breadcrumb: false,
                            submenu: [],
                            notifiable: true,
                        },
                        {
                            key: 'event-competition-management',
                            path: `${APP_PREFIX_PATH}/event-competitions`,
                            title: 'Event Competitions',
                            icon: CarryOutOutlined,
                            breadcrumb: false,
                            submenu: [],
                            notifiable: true,
                        },
                        {
                            key: 'loyalty-club-competition-management',
                            path: `${APP_PREFIX_PATH}/club-competitions`,
                            title: 'Loyalty Club Competitions',
                            icon: TeamOutlined,
                            breadcrumb: false,
                            submenu: [],
                            notifiable: true,
                        },
                    ]
                },
                ...profileTree
            ]
            break;
        case 'admin':
            aclTree = [
                ...dashBoardAdminNavTree,
                ...profileTree,
            ]
            break;
        default:
            aclTree = []
            break;
    }

    return [
        ...aclTree
    ]
}


export default useNavigationConfig;
