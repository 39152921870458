import http from "../../utils/http";
import {COUNTRIES_CURRENCY_FETCHED, COUNTRIES_CURRENCY_FETCHING, COUNTRIES_CURRENCY_FINISHED} from "../constants/CountriesCurrency";

export function fetchCountriesCurrency() {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {
        const userCountryId = getState()?.auth?.user?.country_id;
        // you can use api here
        dispatch({
            type: COUNTRIES_CURRENCY_FETCHING
        })
        http.get('/countries/currency').then(res => {
            dispatch({
                type: COUNTRIES_CURRENCY_FETCHED,
                payload: {
                    userCountryCurrency: res.data.data.find(c => c.country_id === userCountryId),
                    countriesCurrency: res.data.data
                }
            })
        }).finally(() => {
            dispatch({
                type: COUNTRIES_CURRENCY_FINISHED
            })
        })
    }
}