import React from "react";
import {useMutation, useQuery} from "react-query";
import http from "utils/http";
import {message} from "antd";

export function useGetNotifications() {
    return useQuery('Notifications', () => {
        return http.get('/notifications').then(res => {
            return res.data.data
        })
    });
}

export function useGetReadNotifications() {
    return useQuery('ReadNotifications', () => {
        return http.get('/notifications/read').then(res => {
            return res.data.data
        })
    });
}

export function useGetUnReadNotifications() {
    return useQuery('UnReadNotifications', () => {
        return http.get('/notifications/unread').then(res => {
            return res.data.data
        })
    });
}

export function useReadNotification() {
    return useMutation((Notification) => {
        return http.delete('/notifications/' + Notification.id);
    }, {
        onSuccess: (data) => {
            if (data) {
                message.success('notification marked as read.')
            }
        },
        onError: (error) => {
            message.error("connection error")
        }
    });
}

export function useClearNotifications() {
    return useMutation(() => {
        return http.delete('/notifications/');
    }, {
        onSuccess: (data) => {
            if (data) {
                message.success('notifications cleared.')
            }
        },
        onError: (error) => {
            message.error("connection error")
        }
    });
}