import React, {lazy, Suspense, useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {useDispatch, useSelector} from "react-redux";
import {fetchUnreadNotifications} from "../../redux/actions/Notification";
import {fetchCountriesCurrency} from "../../redux/actions/CountriesCurrency";

const SponsorRoutes = () => {
    return (
        <>
            <Route exact={true} path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./dashboards/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/dashboards/sponsor`}
                   component={lazy(() => import(`./dashboards/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/competitions`} exact
                   component={lazy(() => import(`./competitions/sponsor`))}/>

            <Route path={`${APP_PREFIX_PATH}/event-competitions`} exact
                   component={lazy(() => import(`./competitions-event/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/event-competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions-event/sponsor`))}/>

            <Route path={`${APP_PREFIX_PATH}/club-competitions`} exact
                   component={lazy(() => import(`./competitions-club/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/club-competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions-club/sponsor`))}/>

            {/*<Route path={`${APP_PREFIX_PATH}/club-competitions`} exact*/}
            {/*       component={lazy(() => import(`./competitions-club/sponsor`))}/>*/}

            <Route path={`${APP_PREFIX_PATH}/orders/:orderId/invoice`}
                   component={lazy(() => import(`./orders/invoice/viewInvoice`))} exact/>
            <Route path={`${APP_PREFIX_PATH}/orders/:orderId`} exact component={lazy(() => import(`./orders`))}/>
            <Route path={`${APP_PREFIX_PATH}/orders`} exact component={lazy(() => import(`./orders`))}/>
            <Route path={`${APP_PREFIX_PATH}/quests/:questId`} exact
                   component={lazy(() => import(`./quests/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/quests`} exact component={lazy(() => import(`./quests/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/miniGames/:miniGameId`} exact
                   component={lazy(() => import(`./miniGames/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/miniGames`} exact component={lazy(() => import(`./miniGames/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/surveys/:surveyId`} exact
                   component={lazy(() => import(`./surveys/sponsor`))}/>
            <Route path={`${APP_PREFIX_PATH}/surveys`} exact component={lazy(() => import(`./surveys/sponsor`))}/>

            <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))}/>
            {/*<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards/sponsor`}/>*/}
        </>
    )
}

const AdminRoutes = () => {
    return (
        <>
            <Route exact={true} path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./dashboards/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))}/>
            <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))}/>
            <Route path={`${APP_PREFIX_PATH}/participants`} component={lazy(() => import(`./participants`))}/>
            <Route path={`${APP_PREFIX_PATH}/surveys/:surveyId`} exact
                   component={lazy(() => import(`./surveys/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/surveys`} exact component={lazy(() => import(`./surveys/admin`))}/>
            {/*<Route path={`${APP_PREFIX_PATH}/surveys`} component={lazy(() => import(`./surveys`))}/>*/}
            <Route path={`${APP_PREFIX_PATH}/miniGames/:miniGameId`} exact
                   component={lazy(() => import(`./miniGames/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/miniGames`} exact component={lazy(() => import(`./miniGames/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/tickets`} component={lazy(() => import(`./tickets`))}/>
            <Route path={`${APP_PREFIX_PATH}/wallet-withdraw-requests`}
                   component={lazy(() => import(`./walletWithdrawRequests`))}/>
            <Route path={`${APP_PREFIX_PATH}/orders/:orderId/invoice`}
                   component={lazy(() => import(`./orders/invoice/viewInvoice`))} exact/>
            <Route path={`${APP_PREFIX_PATH}/orders/:orderId`} exact component={lazy(() => import(`./orders`))}/>
            <Route path={`${APP_PREFIX_PATH}/orders`} exact component={lazy(() => import(`./orders`))}/>
            <Route path={`${APP_PREFIX_PATH}/quests/:questId`} exact component={lazy(() => import(`./quests`))}/>
            <Route path={`${APP_PREFIX_PATH}/quests`} exact component={lazy(() => import(`./quests`))}/>

            <Route path={`${APP_PREFIX_PATH}/competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/competitions`} exact component={lazy(() => import(`./competitions/admin`))}/>

            <Route path={`${APP_PREFIX_PATH}/event-competitions`} exact
                   component={lazy(() => import(`./competitions-event/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/event-competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions-event/admin`))}/>

            <Route path={`${APP_PREFIX_PATH}/club-competitions`} exact
                   component={lazy(() => import(`./competitions-club/admin`))}/>
            <Route path={`${APP_PREFIX_PATH}/club-competitions/:competitionId`} exact
                   component={lazy(() => import(`./competitions-club/admin`))}/>


            <Route path={`${APP_PREFIX_PATH}/industries`} component={lazy(() => import(`./industries`))}/>
            <Route path={`${APP_PREFIX_PATH}/brands`} component={lazy(() => import(`./brands`))}/>
            <Route path={`${APP_PREFIX_PATH}/sliders`} component={lazy(() => import(`./sliders`))}/>
            <Route path={`${APP_PREFIX_PATH}/interests`} component={lazy(() => import(`./interests`))}/>
            <Route path={`${APP_PREFIX_PATH}/shortLinks`} component={lazy(() => import(`./shortLinks`))}/>
            <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))}/>
            <Route path={`${APP_PREFIX_PATH}/currencies`} component={lazy(() => import(`./currencies`))}/>
            <Route path={`${APP_PREFIX_PATH}/countries`} component={lazy(() => import(`./countries`))}/>
            <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))}/>

            {/*content*/}
            <Route path={`${APP_PREFIX_PATH}/tutorials`} component={lazy(() => import(`./tutorials`))}/>
            <Route path={`${APP_PREFIX_PATH}/popup`} component={lazy(() => import(`./popup`))}/>
            <Route path={`${APP_PREFIX_PATH}/faqs`} component={lazy(() => import(`./faqs`))}/>

            {/*quest price*/}
            <Route path={`${APP_PREFIX_PATH}/questPrices/questType`}
                   component={lazy(() => import(`./questPrices/questTypePrice`))}/>
            <Route path={`${APP_PREFIX_PATH}/questPrices/country`}
                   component={lazy(() => import(`./questPrices/country`))}/>
            <Route path={`${APP_PREFIX_PATH}/questPrices/gender`}
                   component={lazy(() => import(`./questPrices/gender`))}/>

            {/*<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`}/>*/}
        </>
    );
}


export const AppViews = () => {

    const userType = useSelector(state => state.auth.type)
    let routes = null

    if (userType == 'brand') {
        routes = SponsorRoutes();
    } else if (userType == 'admin') {
        routes = AdminRoutes();
    } else {
        routes = SponsorRoutes();
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUnreadNotifications())
        dispatch(fetchCountriesCurrency())
    }, [])

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                {routes}
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);
