import axios from "axios";

import {API_BASE_URL, FRONTEND_SERVER_URL} from "../constants/ApiConstant";
import {message} from "antd";
import {AUTH_TOKEN} from "../redux/constants/Auth";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers.post["Content-Type"] = "application/json";
    config.headers["Authorization"] = localStorage.getItem(AUTH_TOKEN) ? 'Bearer ' + localStorage.getItem(AUTH_TOKEN) : "";

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(
    function (response) {
        // Call was successful, don't do anything special.
        return response;
    },
    function (error) {
        switch (error.response.status) {
            case 401: // Not logged in
                // toast.error('خطا در احراز هویت.')
                localStorage.clear()
                window.location.href = `${FRONTEND_SERVER_URL}/auth/login`;
                break;
            case 403:
                message.error('You have not permitted to do this action.')
                return Promise.reject(error);
            case 422:
                // message.error('input validation failed. please put valid inputs.')
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    message.error(value)
                }
                return Promise.reject(error);
            case 419: // Session expired
            case 503: // Down for maintenance
                // Bounce the user to the login screen with a redirect back
                // window.location.reload();
                // console.log(error.response);
                break;
            case 500:
                if (error?.response?.data?.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Oops, something went wrong!  The team have been notified.');
                }
                return Promise.reject(error);
            default:
                // Allow individual requests to handle other errors
                return Promise.reject(error);
        }
    });

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}

export default http;