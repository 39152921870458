import http from "../../utils/http";
import {FETCHED, FETCHING, FINISHED, SEEN} from "../constants/Notification";
import {ORDER_NOTIFICATION_TYPES} from "../../constants/OrderConstant";
import {COMPETITION_NOTIFICATION_TYPES} from "../../constants/NotificationConstant";

export function fetchUnreadNotifications() {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {
        // you can use api here
        dispatch({
            type: FETCHING
        })
        http.get('/notifications/unread').then(res => {
            dispatch({
                type: FETCHED,
                payload: res.data.data
            })
        }).finally(() => {
            dispatch({
                type: FINISHED
            })
        })
    }
}

export function deleteNotification(notification) {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {
        // you can use api here
        http.delete('/notifications/' + notification.id).then(res => {
            dispatch({
                type: SEEN,
                payload: res.data.data
            })
        })
    }
}

export function deleteOrderStatusChangeNotifications() {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {

        const notifications = getState().notifications?.unreads?.filter(notification => notification.type === 'App\\Notifications\\OrderStatusChangedNotification')

        notifications.map(notification => {
            // you can use api here
            http.delete('/notifications/' + notification.id).then(res => {
                dispatch({
                    type: SEEN,
                    payload: res.data.data
                })
            })
        })

        console.log("deleteOrderStatusChangeNotifications");
        dispatch(fetchUnreadNotifications())
    }
}

export function deleteOrderNotifications() {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {

        const notifications = getState().notifications?.unreads?.filter(notification => ORDER_NOTIFICATION_TYPES.includes(notification.type))

        notifications.map(notification => {
            // you can use api here
            http.delete('/notifications/' + notification.id).then(res => {
                dispatch({
                    type: SEEN,
                    payload: res.data.data
                })
            })
        })

        console.log("deleteOrderNotifications");
        dispatch(fetchUnreadNotifications())
    }
}

export function deleteCompetitionNotifications() {
    // The `extraArgument` is the third arg for thunk functions
    return (dispatch, getState) => {

        const notifications = getState().notifications?.unreads?.filter(notification => COMPETITION_NOTIFICATION_TYPES.includes(notification.type))

        notifications.map(notification => {
            // you can use api here
            http.delete('/notifications/' + notification.id).then(res => {
                dispatch({
                    type: SEEN,
                    payload: res.data.data
                })
            })
        })

        // console.log("deleteCompetitionNotifications");
        dispatch(fetchUnreadNotifications())
    }
}