import {BACKEND_SERVER_URL} from "../constants/ApiConstant";

const dev = {
	API_ENDPOINT_URL: 'http://api.theqame.test/api'
	// API_ENDPOINT_URL: 'http://api.theqame.test/api/'
};

const prod = {
  API_ENDPOINT_URL: `${BACKEND_SERVER_URL}/api`
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
