import React, {useState} from 'react';
import {Menu, Dropdown, Badge, Avatar, List, Button, Modal, Space} from 'antd';
import {
    MailOutlined,
    BellOutlined,
    WarningOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import {useClearNotifications, useReadNotification} from "../../hooks/notificationHook";
import Loading from "../shared-components/Loading";
import moment from "moment";
import {useQueryClient} from "react-query";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {useSelector} from "react-redux";

const getIcon = icon => {
    switch (icon) {
        case 'mail':
            return <MailOutlined/>;
        case 'alert':
            return <WarningOutlined/>;
        case 'check':
            return <CheckCircleOutlined/>
        default:
            return <MailOutlined/>;
    }
}

const getNotificationBody = (list, readNotification) => {
    return list?.length > 0 ?
        <List
            size="small"
            itemLayout="horizontal"
            dataSource={list}
            renderItem={item => (
                <List.Item className="list-clickable" onClick={() => readNotification(item)}>
                    <Flex alignItems="center">
                        <div className="pr-2">
                            {item.img ? <Avatar src={`/img/avatars/${item.img}`}/> :
                                <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)}/>}
                        </div>
                        <Space direction={'vertical'} size={'small'}>
                            <span className="font-weight-bold text-dark d-block">{item?.data?.title}</span>
                            <small
                                className="ml-auto text-gray-light d-block">{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</small>
                        </Space>
                    </Flex>
                </List.Item>
            )}
        />
        :
        <div className="empty-notification">
            <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty"/>
            <p className="mt-3">You have viewed all notifications</p>
        </div>;
}

export const NavNotification = () => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false)
    const [selectedNotification, setSelectedNotification] = useState(null)

    // const {data, isLoading} = useGetUnReadNotifications();
    const {unreads, loading: isLoading} = useSelector(state => state.notifications)
    const {isLoading: isClearLoading, mutateAsync: clearMutate} = useClearNotifications();
    const {isLoading: isReadLoading, mutateAsync: readMutate} = useReadNotification();
    const queryClient = useQueryClient();

    const readNotification = (notification) => {
        setSelectedNotification(notification)
        setVisibleModal(true)
    }

    const makeNotificationRead = () => {
        readMutate(selectedNotification)
            .then(res => {
                setVisibleModal(false)
                setSelectedNotification(null)
                queryClient.invalidateQueries("UnReadNotifications")
            })
            .catch(err => console.log(err))
    }

    const handleModalCancel = () => {
        setVisibleModal(false)
    }

    const clearNotifications = () => {
        clearMutate().then(res => {
            queryClient.invalidateQueries("UnReadNotifications")
        })
    }

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    }

    const notificationList = (
        <div className="nav-dropdown nav-notification">
            <div className="nav-notification-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Notification</h4>
                <Button className="text-primary" type="text" onClick={clearNotifications} size="small">Clear </Button>
            </div>
            <div className="nav-notification-body">
                {isClearLoading ? (<Loading/>) : (getNotificationBody(unreads, readNotification))}
            </div>
            {
                unreads?.length > 0 ?
                    <div className="nav-notification-footer">
                        <a className="d-block" href="#/">View all</a>
                    </div>
                    :
                    null
            }
        </div>
    );

    return (
        <>
            <Dropdown
                placement="bottomRight"
                overlay={notificationList}
                onOpenChange={handleVisibleChange}
                open={visible}
                trigger={['click']}
                overlayStyle={{zIndex: '998'}}
            >
                <Menu mode="horizontal">
                    <Menu.Item key="notification">
                        <Badge count={unreads?.length}>
                            <BellOutlined className="nav-icon mx-auto" type="bell"/>
                        </Badge>
                    </Menu.Item>
                </Menu>
            </Dropdown>

            <Modal
                title="Read Notification"
                open={visibleModal}
                onCancel={handleModalCancel}
                okText={'mark as read'}
                okButtonProps={{loading: isReadLoading}}
                onOk={makeNotificationRead}
            >
                <h3>{selectedNotification?.data?.title ?? 'no title'}</h3>
                <p>{selectedNotification?.data?.description ?? 'no description'}</p>
                <p>{selectedNotification?.data?.url ? (
                    <Button
                        type={'dashed'}
                        href={APP_PREFIX_PATH + '/' + selectedNotification?.data?.url}
                    >
                        Link
                    </Button>
                ) : 'no description'}</p>

            </Modal>
        </>
    )
}


export default NavNotification;
