import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT, AUTH_TYPE, AUTH_USER
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
} from "../actions/Auth";

import JwtAuthService from "../../services/JwtAuthService";

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({payload}) {
		try {
			const data = yield call(JwtAuthService.login, payload);
			if (data.message) {
				yield put(showAuthMessage(data.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, data.token);
				localStorage.setItem(AUTH_TYPE, data.user.type);
				localStorage.setItem(AUTH_USER, JSON.stringify(data.user));
				yield put(authenticated(data));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.removeItem(AUTH_TOKEN);
			localStorage.removeItem(AUTH_TYPE);
			localStorage.removeItem(AUTH_USER);
			yield put(signOutSuccess(null));
		} catch (err) {
			yield put(showAuthMessage(err));
		}


	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
  ]);
}
