import {SURVEY_CLEARED, SURVEY_SELECTED} from "../constants/QuestActionSubForms";

const initState = {
    surveyId: undefined
}

const QuestActionSubForms = (state = initState, action) => {
    switch (action.type) {
        case SURVEY_SELECTED:
            return {
                ...state,
                surveyId: action.payload.surveyId,
            }
        case SURVEY_CLEARED:
            return {
                ...state,
                surveyId: undefined,
            }
        default:
            return state;
    }
}

export default QuestActionSubForms