import {
    FETCH,
    FETCHING,
    FETCHED, FINISHED
} from '../constants/Notification';

const initState = {
    loading: false,
    unreads: []
}

const notification = (state = initState, action) => {
    switch (action.type) {
        case FINISHED:
            return {
                ...state,
                loading: false,
            }
        case FETCHING:
            return {
                ...state,
                loading: true,
            }
        case FETCHED:
            return {
                ...state,
                loading: false,
                unreads: action.payload
            }
        default:
            return state;
    }
}

export default notification