import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import QuestActionSubForms from "./QuestActionSubForms";
import Notification from "./Notification";
import CountriesCurrency from "./CountriesCurrency";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    questActionSubForms: QuestActionSubForms,
    notifications: Notification,
    countriesCurrency: CountriesCurrency,
});

export default reducers;