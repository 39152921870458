import React from "react";
import {Menu, Dropdown, Avatar} from "antd";
import {connect, useSelector} from 'react-redux'
import {
    EditOutlined,
    SettingOutlined,
    ShopOutlined,
    QuestionCircleOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {signOut} from 'redux/actions/Auth';
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {generateImageLink} from "../../utils/AssetLinkGenerator";

const menuItem = [
    // {
    // 	title: "Edit Profile",
    // 	icon: EditOutlined ,
    // 	path: `${APP_PREFIX_PATH}/profile/edit`
    // },

    // {
    // 	title: "Account Setting",
    // 	icon: SettingOutlined,
    // 	path: "/"
    // },
    // {
    // 	title: "Billing",
    // 	icon: ShopOutlined ,
    // 	path: "/"
    // },
    // {
    // 	title: "Help Center",
    // 	icon: QuestionCircleOutlined,
    // 	path: "/"
    // }
]

function getProfileImage(user) {
    if (user?.brands?.length) {
      //image of the first brand
      return user?.brands[0].logo ? generateImageLink(user?.brands[0].logo, true) :  "/img/avatars/avatar.png";
    }
    return "/img/avatars/avatar.png";
}

export const NavProfile = ({signOut}) => {

    const user = useSelector(state => state.auth.user)

    // const profileImg = "/img/avatars/thumb-1.jpg";
    const profileImg = getProfileImage(user);

    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    {/*<Avatar size={45} src={profileImg} />*/}
                    <div className="pl-3">
                        <h4 className="mb-0">{user.name}</h4>
                        {/*<span className="text-muted">Frontend Developer</span>*/}
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon}/>
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight"
                  overlay={profileMenu}
                  trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item key="profile">
                    <Avatar src={profileImg}/>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, {signOut})(NavProfile)
